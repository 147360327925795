import { FC } from "react";

interface IconCheckProps {
  className?: string;
  fill?: string;
  height?: string;
  width?: string;
  bg?: string;
}

const IconCheck: FC<IconCheckProps> = ({ fill, width, height, bg }) => {
  return (
    <svg
      width={width ? width : "20"}
      height={height ? height : "20"}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.1948 18.7695C8.35471 18.8016 6.54643 18.2873 4.99862 17.2918C3.45081 16.2962 2.23298 14.864 1.49914 13.1763C0.765304 11.4885 0.54841 9.62112 0.875889 7.81013C1.20337 5.99915 2.06051 4.32594 3.33893 3.0021C4.61735 1.67826 6.25962 0.763246 8.05807 0.372764C9.85653 -0.0177184 11.7304 0.133872 13.4427 0.808364C15.155 1.48286 16.6288 2.64996 17.6778 4.16208C18.7268 5.6742 19.3038 7.46343 19.336 9.3035C19.3762 11.7702 18.4364 14.1521 16.7227 15.9267C15.009 17.7013 12.6613 18.7236 10.1948 18.7695Z"
        fill={bg ? bg : "white"}
      />
      <path
        d="M8.83983 12.2782C8.75836 12.2798 8.67739 12.2652 8.60158 12.2353C8.52578 12.2054 8.45664 12.1608 8.39815 12.1041L6.50496 10.2759C6.38993 10.1609 6.32468 10.0053 6.32326 9.84263C6.32183 9.67998 6.38434 9.52328 6.49733 9.40628C6.61032 9.28927 6.76475 9.22133 6.92735 9.21707C7.08995 9.21282 7.24771 9.2726 7.36666 9.38354L9.25986 11.2118C9.34809 11.297 9.40907 11.4065 9.43509 11.5264C9.46111 11.6462 9.451 11.7711 9.40605 11.8853C9.36109 11.9994 9.28331 12.0977 9.18252 12.1676C9.08174 12.2375 8.96248 12.276 8.83983 12.2782Z"
        fill={fill ? fill : "#000"}
      />
      <path
        d="M8.8385 12.2783C8.71585 12.2804 8.59532 12.2461 8.49216 12.1797C8.389 12.1134 8.30783 12.0179 8.25892 11.9054C8.21001 11.7929 8.19555 11.6684 8.21737 11.5477C8.23919 11.427 8.29631 11.3155 8.38151 11.2272L12.6474 6.80975C12.7624 6.69473 12.918 6.62948 13.0806 6.62805C13.2433 6.62663 13.4 6.68914 13.517 6.80213C13.634 6.91512 13.7019 7.06955 13.7062 7.23215C13.7104 7.39475 13.6507 7.55251 13.5397 7.67146L9.27384 12.0889C9.21737 12.1477 9.14983 12.1946 9.07511 12.2271C9.00039 12.2596 8.91998 12.277 8.8385 12.2783Z"
        fill={fill ? fill : "#000"}
      />
    </svg>
  );
};

export default IconCheck;
