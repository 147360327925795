import { FC } from "react";

interface IconPlayVideoProps {
  className?: string;
}

const IconPlayVideo: FC<IconPlayVideoProps> = () => {
  return (
    <svg width="88" height="88" viewBox="0 0 94 94" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="94" height="94" rx="47" fill="white" />
      <path
   
        d="M41.9179 54.8575L55.2637 47.8904C56.0232 47.4939 56.0232 46.5061 55.2637 46.1096L41.9179 39.1425C41.0916 38.7112 40.2083 39.3125 40.2083 40.0329L40.2083 53.9671C40.2083 54.6875 41.0916 55.2888 41.9179 54.8575ZM56.2589 49.6474C58.4693 48.4935 58.4692 45.5065 56.2589 44.3526L42.9131 37.3855C40.7649 36.264 38.125 37.7237 38.125 40.0329V53.9671C38.125 56.2763 40.765 57.736 42.9131 56.6145L56.2589 49.6474Z"
        fill="black"
      />
      <path
        d="M55.2637 47.8904L41.9179 54.8575C41.0916 55.2888 40.2083 54.6875 40.2083 53.9671L40.2083 40.0329C40.2083 39.3125 41.0916 38.7112 41.9179 39.1425L55.2637 46.1096C56.0232 46.5061 56.0232 47.4939 55.2637 47.8904Z"
        fill="black"
      />
    </svg>
  );
};

export default IconPlayVideo;
