"use client";
import { ReviewGetDTO } from "@alba-cars/common-modules";
import React, { useMemo, useState } from "react";

import IconFirstStar from "../Icons/IconFirstStar";
import IconStars from "../Icons/IconStars";

enum ReviewTag {
  BEST_CARS = "Best Cars",
  EXCELLENT_SERVICE = "Excellent Service",
  GREAT_PRICES = "Great Prices",
  PROFESSIONAL_TEAM = "Professional Team",
  FAST_DELIVERY = "Fast Delivery",
  RELIABLE = "Reliable",
}

const getRandomTags = (count: number): ReviewTag[] => {
  const tags = Object.values(ReviewTag);
  const shuffled = [...tags].sort(() => Math.random() - 0.5);
  return shuffled.slice(0, count);
};

interface ReviewCardProps {
  review: ReviewGetDTO & { rating: number };
}

const ReviewCard: React.FC<ReviewCardProps> = ({ review }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 150;
  const shouldTruncate = (review.comment?.length ?? 0) > maxLength;

  const displayText = isExpanded
    ? review.comment
    : shouldTruncate
      ? `${review.comment?.slice(0, maxLength)}...`
      : review.comment;

  const reviewTags = useMemo(() => getRandomTags(2), []);

  const TagsComponent = () => (
    <>
      {reviewTags.map((tag, index) => (
        <span key={index} className="flex items-center rounded-full bg-secondary-light py-1  md:px-4 xl:w-fit px-3 w-fit">
          <IconFirstStar fillColor="#FF3448" width="25" height="25" />
          <span className="text-sm font-medium font-inter">{tag}</span>
        </span>
      ))}
    </>
  );

  return (
    <div className="rounded-[2rem] bg-white p-4 cursor-pointer">
      <div className="flex flex-col md:flex-row md:justify-between md:space-x-9">
        <div className="flex flex-row items-center md:block md:justify-between">
          <div className="flex flex-col md:ml-0 md:hidden md:items-end">
            <h5 className="urbanist text-xl font-semibold text-primary-foreground md:mb-2">
              {review.username ?? "Unknown user"}
            </h5>
            <IconStars fillColor="#FEBC25" rating={review.rating ?? 5} />
            <div className="mt-4 flex flex-col space-y-2 md:mt-2">
              <TagsComponent />
            </div>
          </div>
        </div>

        <div className="md:flex-1">
          <div className="hidden items-center md:flex md:flex-row md:justify-between">
            <h5 className="urbanist text-xl font-semibold text-primary-foreground">
              {review.username ?? "Unknown user"}
            </h5>
            <IconStars fillColor="#FEBC25" rating={review.rating ?? 5} />
          </div>

          <div className="py-5">
            <p className="inter text-base font-normal text-[#4D4D4D]">
              {displayText ?? "No details about this review"}
            </p>
            {shouldTruncate && (
              <button
                onClick={() => setIsExpanded(!isExpanded)}
                className="mt-2 font-medium text-primary hover:text-primary/80"
              >
                {isExpanded ? "Show less" : "Read more"}
              </button>
            )}
          </div>

          <div className="hidden items-center md:flex md:flex-row md:space-x-3">
            <TagsComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCard;
