"use client";
import Image from "next/image";
import React, { useEffect, useRef, useState } from "react";

import Icon from "@/components/Icons/Icon";
import IconCheck from "@/components/Icons/IconCheck";

interface AnimatedCarProps {
  carBodySrc: string;
  frontWheelSrc: string;
  rearWheelSrc: string;
  wheelPositions: {
    desktop: { front: { x: number; y: number }; rear: { x: number; y: number } };
    mobile: { front: { x: number; y: number }; rear: { x: number; y: number } };
  };
}

const AnimatedWarrantyCar: React.FC<AnimatedCarProps> = ({
  carBodySrc,
  frontWheelSrc,
  rearWheelSrc,
  wheelPositions,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationComplete, setAnimationComplete] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const carContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 1536);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.unobserve(entry.target);
        }
      },
      { threshold: 0.2 },
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;

    const animationEndHandler = () => {
      setAnimationComplete(true);
    };

    const carElement = carContainerRef.current;
    if (carElement) {
      carElement.addEventListener("animationend", animationEndHandler);

      return () => {
        carElement.removeEventListener("animationend", animationEndHandler);
      };
    }
  }, [isVisible]);

  const currentWheelPositions = isMobile ? wheelPositions.mobile : wheelPositions.desktop;

  const carWidth = isMobile ? 350 : 450;
  const carHeight = isMobile ? 300 : 150;
  const wheelWidth = isMobile ? 46 : 59;
  const wheelHeight = isMobile ? 31 : 40;

  return (
    <div className="relative rounded-3xl py-4" ref={containerRef}>
      <div className="">
        <div className="absolute left-1/2 top-14 z-10 flex w-56 w-fit -translate-x-1/2 items-center justify-center rounded-xl bg-success-lighter px-4 py-2 font-urbanist text-sm font-medium text-white">
          <Icon icon={<IconCheck fill="#00C514" />} />
          <span className="ml-1"> One year free warranty!</span>
        </div>
        <Image
          src="/assets/images/phone.png"
          alt="Phone"
          width={104}
          height={104}
          className="absolute left-1/2 top-1/2 z-0 w-[5.5rem] -translate-x-1/2 -translate-y-3/4 lg:w-[6.5rem]"
        />
      </div>

      <div className="relative h-[250px] overflow-hidden xl:h-[300px]">
        <div className="relative">
          <div
            ref={carContainerRef}
            className={
              isMobile
                ? `relative mx-auto w-[350px] ${isVisible ? (animationComplete ? "car-centered" : "car-animation-container") : "translate-x-full"}`
                : `relative mx-auto w-[450px] ${isVisible ? (animationComplete ? "car-centered" : "car-animation-container") : "translate-x-full"}`
            }
          >
            <Image
              src={carBodySrc}
              alt="Car body"
              width={carWidth}
              height={carHeight}
              className="absolute top-24 z-10 2xl:top-20"
            />

            <div
              className="absolute z-20"
              style={{ left: `${currentWheelPositions.front.x}px`, top: `${currentWheelPositions.front.y}px` }}
            >
              <Image
                src={frontWheelSrc}
                alt="Front wheel"
                width={wheelWidth}
                height={wheelHeight}
                className={isVisible ? "wheel" : ""}
              />
            </div>

            <div
              className="absolute z-20"
              style={{ left: `${currentWheelPositions.rear.x}px`, top: `${currentWheelPositions.rear.y}px` }}
            >
              <Image
                src={rearWheelSrc}
                alt="Rear wheel"
                width={wheelWidth}
                height={wheelHeight}
                className={isVisible ? "wheel" : ""}
              />
            </div>
          </div>
        </div>
      </div>

      <p className="urbanist mx-auto max-w-xs text-center text-xl font-medium">
        Every car includes a free one-year warranty
      </p>
    </div>
  );
};

export default AnimatedWarrantyCar;
