"use client";
import { motion } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import { whyAlbaItems } from "@/utils/constants";

const WhyAlba: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 0.1 }}
      variants={containerVariants}
      className="items-left mb-6 flex flex-col space-y-7 rounded-3xl bg-white py-8 lg:mb-28 lg:items-center lg:space-y-12 lg:rounded-6xl lg:py-20"
    >
      <motion.div variants={itemVariants} className="px-5 text-left lg:px-0 lg:text-center">
        <h2 className="mb-4 text-left font-urbanist text-3xl font-semibold lg:text-center lg:text-5xl">
          Why Choose Alba Cars
        </h2>
        <h3 className="inter mx-auto max-w-4xl text-left text-sm text-[#292D34CC] lg:text-center">
          <strong>Buy used cars in Dubai </strong>with a premium experience, great deals, and hassle-free financing for
          your dream car.
        </h3>
      </motion.div>

      <motion.div
        variants={itemVariants}
        className="flex flex-col justify-center space-y-3 px-5 lg:w-3/4 lg:flex-row lg:space-x-7 lg:space-y-0"
      >
        {whyAlbaItems.map((item, index) => (
          <motion.div
            key={index}
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            className="urbanist flex items-center justify-between rounded-3xl bg-secondary-light px-5 text-xl font-medium text-primary-foreground lg:flex-col lg:justify-center lg:p-10 lg:px-10"
          >
            <div className="lg:order-0 relative order-3 mb-4 h-28 w-40 lg:h-36 lg:w-44 2xl:h-60 2xl:w-80">
              <Image src={item.src} alt={item.alt} layout="fill" objectFit="contain" objectPosition="bottom" />
            </div>
            <span className="lg:order-7">
              <h4>{item.label}</h4>
            </span>
          </motion.div>
        ))}
      </motion.div>
      <motion.div variants={itemVariants}>
        <div className="mx-auto px-5 text-left lg:max-w-3xl lg:px-0 lg:text-center">
          <h3 className="inter mx-auto text-left text-sm text-[#292D34CC] lg:max-w-3xl lg:px-0 lg:text-center">
            Leading the used car market, our{" "}
            <Link href={"/buy-used-cars-uae"} className="text-left text-[#005DFFCC]">
              used car showroom in Dubai
            </Link>{" "}
            offers certified, quality-checked vehicles. Experience top customer service at the best{" "}
            <Link href={"/buy-used-cars-uae"} className="text-[#005DFFCC]">
              car showroom in Dubai
            </Link>
          </h3>
        </div>
      </motion.div>
      <motion.div
        variants={itemVariants}
        className="mx-5 flex rounded-2xl border border-[#E4E8EC] p-4 lg:mx-0 lg:w-3/4 lg:items-center lg:rounded-full lg:p-8"
      >
        <div>
          <Image alt="why-alba-image" src={"/assets/images/certification.svg"} width={235} height={228}></Image>
        </div>
        <div className="pl-2 lg:pl-10">
          <h2 className="urbanist mb-4 text-xl font-semibold text-primary-foreground">Certified used cars in UAE</h2>
          <p className="inter mx-auto max-w-xl text-sm leading-6 text-[#292D34CC]">
            As the largest used car showroom in the UAE, we offer a wide range of luxury brands with special deals. All
            our pre-owned cars are thoroughly inspected and verified. <br /> Easily search by make, model, mileage, and
            more to find your perfect car.
          </p>
        </div>
      </motion.div>
    </motion.section>
  );
};

export default WhyAlba;
