import { FC } from "react";

interface IconSearchProps {
  className?: string;
}

const IconSearch: FC<IconSearchProps> = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.58464" cy="10.0833" r="7.91667" stroke="white" strokeWidth="2" />
      <path d="M15.418 15.9166L18.3346 18.8333" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
};

export default IconSearch;
