"use client";

import { SalesAgentResponseDTO } from "@alba-cars/common-modules";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import IconArrowRight from "@/components/Icons/IconArrowRight";
import Container from "@/components/layout/Container";
import TeamCard from "@/components/ui/_TeamCard";
import "swiper/css";
import "swiper/css/navigation";

interface SalesTeamsSwiperProps {
  salesAgents: SalesAgentResponseDTO[];
}

export default function SalesTeamsSwiper({ salesAgents }: SalesTeamsSwiperProps) {
  if (!salesAgents || salesAgents.length === 0) {
    return null;
  }

  return (
    <section>
      <Container>
        <div className="relative">
          <Swiper
            modules={[Navigation, Virtual]}
            navigation={{
              nextEl: ".swiper-next",
              prevEl: ".swiper-prev",
              enabled: true,
            }}
            preventInteractionOnTransition={true}
            virtual={{
              addSlidesAfter: 1,
              addSlidesBefore: 1,
              enabled: true,
            }}
            slidesPerView={1}
            spaceBetween={16}
            breakpoints={{
              320: {
                slidesPerView: 1.2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 24,
              },
              1280: {
                slidesPerView: 4,
                spaceBetween: 24,
              },
            }}
          >
            {salesAgents.map((agent, idx) => (
              <SwiperSlide key={`agent-${agent.id}-${idx}`}>
                <TeamCard agent={agent} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="swiper-next absolute -right-4 top-1/2 z-20 flex hidden h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white text-black shadow-soft-grey lg:block lg:flex">
            <IconArrowRight height="18" width="18" />
          </div>
          <div className="swiper-prev lg:flex absolute -left-4 top-1/2 z-20 flex hidden h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white text-black shadow-soft-grey lg:block">
            <IconArrowRight height="18" width="18" className="rotate-180" />
          </div>
          <div className="pointer-events-none absolute -right-10 top-0 z-10 hidden h-full w-40 bg-slider-gradient lg:block"></div>
        </div>
      </Container>
    </section>
  );
}
