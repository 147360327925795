"use client";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ShowroomVideo from "./ShowroomVideo";
import ShowroomVisitForm from "./ShowroomVisitForm";

export interface ShowroomVisitData {
  Visitor_FullName: string;
  Visitor_Mobile: string;
  Visit_Date: string;
  Visit_Time: string;
  Visit_Interest: "sell my car" | "car viewing";
}

const VisitShowroom: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const {} = useForm<ShowroomVisitData>({
    defaultValues: {
      Visit_Interest: "car viewing",
    },
    mode: "onTouched",
  });

  const motionKey = isMobile ? "mobile" : "desktop";

  const motionProps = isMobile
    ? {}
    : {
        initial: { opacity: 0, y: 20 },
        whileInView: { opacity: 1, y: 0 },
        viewport: { once: false, amount: 0.1 },
        transition: { duration: 0.6 },
      };

  const motionH3Props = {
    initial: { opacity: 0, x: -50 },
    whileInView: { opacity: 1, x: 0 },
    viewport: { once: false },
    transition: { duration: 0.5 },
  };

  const motionDivProps = {
    initial: { opacity: 0, x: 100 },
    whileInView: { opacity: 1, x: 0 },
    viewport: { once: false },
    transition: { duration: 0.6, ease: "easeOut" },
  };

  return (
    <motion.section
      key={motionKey}
      {...motionProps}
      className="overflow-x-hidden rounded-3xl bg-white p-5 lg:mb-32 lg:mt-8 lg:overflow-x-visible lg:rounded-none lg:bg-transparent lg:p-0"
    >
      <motion.h3
        key={motionKey}
        {...(isMobile ? {} : motionH3Props)}
        className="urbanist text-2xl font-semibold text-black lg:text-5xl"
      >
        Visit Our Showroom
      </motion.h3>
      <div className="relative mt-5 w-full lg:mt-12">
        <ShowroomVideo />
        <motion.div
          key={motionKey}
          {...(isMobile ? {} : motionDivProps)}
          className="bg-white pt-6 lg:absolute lg:right-0 lg:top-10 lg:h-fit lg:w-[28rem] lg:-translate-y-1/4 lg:rounded-3xl lg:p-10 lg:pt-10 xl:w-[35rem]"
        >
          <ShowroomVisitForm title="Get in touch" subTitle="Visit our Showroom and Experience the Car of your dreams" />
        </motion.div>
      </div>
    </motion.section>
  );
};

export default VisitShowroom;
