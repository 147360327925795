import { FC } from "react";

interface IconFirstStarProps {
  className?: string;
  fillColor?: string;
  width?: string;
  height?: string;
}

const IconFirstStar: FC<IconFirstStarProps> = ({ fillColor, width = "43", height = "43" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_338_2367)">
        <path
          d="M17.9167 39.4168C17.9167 34.665 18.0126 28.3162 21.3727 24.9562C24.7327 21.5961 31.0815 21.5002 35.8333 21.5002C31.0815 21.5002 24.7327 21.4042 21.3727 18.0442C18.0126 14.6841 17.9167 8.33529 17.9167 3.5835C17.9167 8.33529 17.8207 14.6841 14.4607 18.0442C11.1006 21.4042 4.7518 21.5002 0 21.5002C4.7518 21.5002 11.1006 21.5961 14.4607 24.9562C17.8207 28.3162 17.9167 34.665 17.9167 39.4168Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="clip0_338_2367">
          <rect width="43" height="43" fill={fillColor} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconFirstStar;
