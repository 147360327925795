"use client";
import { ReviewGetDTO } from "@alba-cars/common-modules";
import { motion } from "framer-motion";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import IconArrowRight from "@/components/Icons/IconArrowRight";
import Container from "@/components/layout/Container";
import ReviewCard from "@/components/ui/ReviewCard";
import "swiper/css";
import "swiper/css/navigation";

interface ReviewsSliderProps {
  reviews: (ReviewGetDTO & { rating: number })[];
}

const ReviewsSlider = ({ reviews }: ReviewsSliderProps) => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 0.1 }}
      variants={containerVariants}
      className="relative mx-auto w-full max-w-[90vw] overflow-visible"
    >
      <Container>
        <div className="relative">
          <Swiper
            modules={[Navigation, Virtual]}
            navigation={{
              nextEl: ".swiper-next-arrow",
              prevEl: ".swiper-prev-arrow",
            }}
            preventInteractionOnTransition={true}
            virtual={{
              addSlidesAfter: 1,
              addSlidesBefore: 1,
              enabled: true,
            }}
            slidesPerView={1}
            spaceBetween={24}
            breakpoints={{
              1200: { slidesPerView: 2 },
            }}
          >
            {reviews.map((review) => (
              <SwiperSlide key={review.id}>
                <ReviewCard review={review} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className="swiper-prev-arrow absolute -left-5 top-1/2 z-20 hidden h-12 w-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white text-black shadow-soft-grey lg:flex xxxl:-left-16">
            <IconArrowRight height="18" width="18" className="rotate-180" />
          </div>
          <div className="swiper-next-arrow absolute -right-6  top-1/2 z-20 hidden h-12 w-12 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white text-black shadow-soft-grey lg:flex xxxl:-right-16">
            <IconArrowRight height="18" width="18" />
          </div>
        </div>
      </Container>
    </motion.div>
  );
};

export default ReviewsSlider;
