import { FC } from "react";

interface IconSecondStarProps {
  className?: string;
}

const IconSecondStar: FC<IconSecondStarProps> = () => {
  return (
    <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_338_2375)">
        <path
          d="M18.5846 39.4168C18.5846 34.665 18.6806 28.3162 22.0406 24.9562C25.4007 21.5961 31.7495 21.5002 36.5013 21.5002C31.7495 21.5002 25.4007 21.4042 22.0406 18.0442C18.6806 14.6841 18.5846 8.33529 18.5846 3.5835C18.5846 8.33529 18.4887 14.6841 15.1286 18.0442C11.7686 21.4042 5.41976 21.5002 0.667969 21.5002C5.41976 21.5002 11.7686 21.5961 15.1286 24.9562C18.4887 28.3162 18.5846 34.665 18.5846 39.4168Z"
          fill="white"
        />
        <path
          d="M38.293 16.125C38.293 14.6995 38.3218 12.7948 39.3298 11.7868C40.3378 10.7788 42.2424 10.75 43.668 10.75C42.2424 10.75 40.3378 10.7212 39.3298 9.7132C38.3218 8.70519 38.293 6.80054 38.293 5.375C38.293 6.80054 38.2642 8.70519 37.2562 9.7132C36.2482 10.7212 34.3435 10.75 32.918 10.75C34.3435 10.75 36.2482 10.7788 37.2562 11.7868C38.2642 12.7948 38.293 14.6995 38.293 16.125Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_338_2375">
          <rect width="43" height="43" fill="white" transform="translate(0.667969)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconSecondStar;
