"use client";

import { Suspense, lazy, useEffect, useState } from "react";

import Icon from "@/components/Icons/Icon";
import IconMapPin from "@/components/Icons/IconMapPin";
import IconMapPinHover from "@/components/Icons/IconMapPinHover";
import { Button } from "@/components/ui/buttons/Button";
import { ContactLink } from "../navigation/ContactLink";
import SectionTitle from "../ui/SectionTitle";

// Lazy load the Map component
const LazyMap = lazy(() => import("../features/Map"));

const Contacts: React.FC = () => {
  const [hoveredIconKey, setHoveredIconKey] = useState<string | null>(null);
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    // Use Intersection Observer to detect when map container is visible
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setMapLoaded(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 },
    );

    const mapContainer = document.getElementById("map-container");
    if (mapContainer) {
      observer.observe(mapContainer);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleGetDirections = () => {
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=ALBA+CARS+-+No.+1+Used+Car+Showroom+in+Dubai,+Al+Asayel+St+-+Al+Quoz+-+Al+Quoz+Industrial+Area+1+-+Dubai`,
    );
  };

  return (
    <div className="mb-10">
      <div className="grid grid-cols-1 gap-x-4 rounded-[2rem] bg-white p-3 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
        <div className="p-2 xl:col-span-1 2xl:col-span-1">
          <div className="mb-7 flex items-center justify-between">
            <SectionTitle title="Contacts" showButton={false} showIcon={false} className="!mb-0" />
            <Button
              onClick={handleGetDirections}
              className="flex items-center gap-2 rounded-xl bg-[#FFF1F1] px-4 py-3 text-sm text-primary transition-all duration-200 hover:bg-primary hover:text-white"
              onMouseEnter={() => setHoveredIconKey("get-directions")}
              onMouseLeave={() => setHoveredIconKey(null)}
            >
              <Icon icon={hoveredIconKey === "get-directions" ? <IconMapPinHover /> : <IconMapPin />} />
              <span className="inter text-sm font-semibold">Get directions</span>
            </Button>
          </div>

          <div className="inter flex items-center justify-between rounded-2xl bg-secondary p-3.5 text-sm text-primary-foreground">
            <span className="font-normal"> Phone</span>
            <ContactLink phoneNumber="+97143772503" />
          </div>
          {/* dubai showrrom */}
          <div className="my-6">
            <p className="urbanist text-xl font-semibold text-primary-foreground">Dubai Showroom</p>
            <p className="inter pt-1 font-medium text-[#696969]">
              Showroom 17, 18 & 20, Al Asayel Street, Al Quoz Ind 1, Dubai, United Arab Emirates
            </p>
            <div className="inter flex items-start justify-between pt-4 text-sm text-primary-foreground">
              <div>
                <span className="font-normal">Working hours</span>
              </div>
              <div className="flex flex-col">
                <span className="font-medium">7 days : 10 AM - 10 PM</span>
              </div>
            </div>
          </div>
        </div>
        <div id="map-container" className="h-[400px] md:h-auto xl:col-span-2">
          {mapLoaded && (
            <Suspense fallback={<MapPlaceholder />}>
              <LazyMap lat={23.4241} lng={53.8478} />
            </Suspense>
          )}
          {!mapLoaded && <MapPlaceholder />}
        </div>
      </div>
    </div>
  );
};

// Simple placeholder component to show while map is loading
const MapPlaceholder = () => {
  return (
    <div className="flex h-full w-full items-center justify-center rounded-2xl bg-gray-100">
      <div className="text-center">
        <div className="mx-auto mb-2 h-6 w-6 animate-spin rounded-full border-2 border-primary border-t-transparent"></div>
      </div>
    </div>
  );
};

export default Contacts;
