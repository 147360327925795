"use client";
import { motion } from "framer-motion";
import Link from "next/link";
import React from "react";

import IconFirstStar from "@/components/Icons/IconFirstStar";
import IconSecondStar from "@/components/Icons/IconSecondStar";
import IconThirdStar from "@/components/Icons/IconThirdStar";
import Button from "@/components/ui/buttons/Button";

const steps = [
  {
    icon: <IconFirstStar fillColor="white" />,
    number: "01",
    description: "Fill the Details",
    id: 1,
  },
  {
    icon: <IconSecondStar />,
    number: "02",
    description: "Get Quick Response",
    id: 2,
  },
  {
    icon: <IconThirdStar />,
    number: "03",
    description: "Sell Same Day for Cash",
    id: 3,
  },
];

const SellUsedCars: React.FC = () => {
  const containerVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: "easeOut",
      },
    },
  };

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: false, amount: 0.1 }}
      variants={containerVariants}
      className="mb-3 mt-10 lg:mb-10 lg:mt-28"
    >
      <div className="touch-action-non user-select-none  relative mt-2 flex h-[49rem] w-full items-center justify-center ixs:h-[55rem] xs:h-[60rem] sm:h-[67rem] lg:mt-0 xl:h-[57rem]">
        {/* Desktop Video */}
        <video
          className="pointer-events-none hidden h-full w-full select-none rounded-4xl object-cover object-center md:block"
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          disablePictureInPicture
          disableRemotePlayback
          controlsList="nodownload nofullscreen noremoteplayback"
          onContextMenu={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
          onTouchMove={(e) => e.preventDefault()}
          onTouchEnd={(e) => e.preventDefault()}
          onClick={(e) => e.preventDefault()}
          draggable="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <source media="(min-width: 768px)" src="/assets/videos/sell-video.mp4" type="video/mp4" />
        </video>

        {/* Mobile Video */}
        <video
          className="h-full w-full select-none rounded-3xl rounded-4xl object-cover object-center xs:object-top sm:object-center md:hidden md:object-cover"
          loop
          autoPlay
          muted
          playsInline
          controls={false}
          disablePictureInPicture
          disableRemotePlayback
          controlsList="nodownload nofullscreen noremoteplayback"
          onContextMenu={(e) => e.preventDefault()}
          onTouchStart={(e) => e.preventDefault()}
          onTouchMove={(e) => e.preventDefault()}
          onTouchEnd={(e) => e.preventDefault()}
          onClick={(e) => e.preventDefault()}
          draggable="false"
          tabIndex={-1}
          aria-hidden="true"
        >
          <source media="(max-width: 767px)" src="/assets/videos/sell-used-cars-mobile.mp4" type="video/mp4" />
        </video>

        <motion.div variants={itemVariants} className="absolute top-10 lg:top-14">
          <p className="text-center font-urbanist text-2xl font-semibold text-black lg:text-5xl">
            Sell your used car hassle free
          </p>
          <p className="mx-auto max-w-80 pt-3 text-center font-inter text-xs font-normal text-black text-opacity-80 ixs:max-w-sm ixs:text-sm lg:max-w-4xl lg:pt-6 lg:text-base">
            Get top market value, instant cash, and loan support in Dubai and Abu Dhabi.
            <br />
            Sell your car in 3 easy steps within 2 hours: Fill the Form, free inspection and final offer.
          </p>
        </motion.div>
        <motion.div
          variants={itemVariants}
          className="absolute bottom-20 mx-auto flex w-11/12 flex-col space-y-3 lg:bottom-32 lg:w-fit lg:flex-row lg:space-x-5 lg:space-y-0"
        >
          {steps.map((step) => (
            <Link href={"/sell-used-car-dubai"} key={step.id}>
              <motion.div
                key={step.number}
                variants={itemVariants}
                whileHover={{ scale: 1.05 }}
                className="urbanist flex w-full flex-col items-start justify-between rounded-3xl bg-[#13284059] py-2 pl-6 text-white backdrop-blur-3xl lg:h-36 lg:w-64 lg:py-6 xl:w-80 2xl:w-96"
              >
                <div className="flex items-center space-x-1">
                  <span>{step.icon} </span> <span className="text-xl lg:text-3xl">{step.number}</span>
                </div>
                <p className="text-lg">{step.description}</p>
              </motion.div>
            </Link>
          ))}
        </motion.div>
        <motion.div variants={itemVariants} className="absolute bottom-5 mx-auto w-11/12 lg:bottom-12 lg:w-fit">
          <Link href={"/sell-used-car-dubai"} target="_blank">
            <Button
              buttonText="Sell my car"
              bgColor="primary"
              className="w-full rounded-xl py-4 text-center font-inter text-white hover:bg-primary-dark lg:w-fit lg:px-20"
            />
          </Link>
        </motion.div>
      </div>
    </motion.section>
  );
};

export default SellUsedCars;
