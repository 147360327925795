"use client";

import { VehicleStatusType } from "@alba-cars/common-modules";
import { useQuery } from "@tanstack/react-query";
import Link from "next/link";
import { useRouter, useSearchParams } from "next/navigation";
import React, { useState } from "react";

import Dropdown from "./CustomDropdown";
import { FilterProvider } from "@/app/buy-used-cars-uae/_components/context/FilterContext";
import IconSearch from "@/components/Icons/IconSearch";
import { fetchCarsTotal } from "@/lib/api/client/fetchCarCounts";
import { MAX_SAFE_INTEGER } from "@/lib/utils";
import { NavigationKeys } from "@/utils/constants";

interface HeroSectionSearchProps {}

const HeroSectionSearch: React.FC<HeroSectionSearchProps> = ({}) => {
  const router = useRouter();
  const searchParams = useSearchParams();
  const params = Object.fromEntries(searchParams);

  const [selectedValues, setSelectedValues] = useState<Record<string, number | null>>({
    emiFrom: null,
    emiTo: null,
    priceFrom: null,
    priceTo: null,
  });

  // const { data: carTotals, isLoading } = useQuery({
  //   queryKey: ["car-totals", selectedValues],
  //   queryFn: async () => {
  //     const params = {
  //       filter: {
  //         status: VehicleStatusType.PUBLISHED,
  //         ...(selectedValues.emiFrom !== null
  //           ? { emi: { min: selectedValues.emiFrom, max: selectedValues?.emiTo ?? MAX_SAFE_INTEGER } }
  //           : {}),
  //         ...(selectedValues.emiTo !== null
  //           ? { emi: { max: selectedValues.emiTo, min: selectedValues?.emiFrom ?? 0 } }
  //           : {}),
  //         ...(selectedValues.priceFrom !== null
  //           ? { price: { min: selectedValues.priceFrom, max: selectedValues?.priceTo ?? MAX_SAFE_INTEGER } }
  //           : {}),
  //         ...(selectedValues.priceTo !== null
  //           ? { price: { max: selectedValues.priceTo, min: selectedValues?.priceFrom ?? 0 } }
  //           : {}),
  //       },
  //     };

  //     return await fetchCarsTotal(params);
  //   },
  //   refetchOnWindowFocus: false, // optional, but forces the query to update only when selectedValues change
  // });

  const handleEmiSelect = (fromValue?: number | null, toValue?: number | null): void => {
    setSelectedValues((prev) => ({
      ...prev,
      ...(fromValue !== undefined && { emiFrom: fromValue }),
      ...(toValue !== undefined && { emiTo: toValue }),
    }));
  };

  const handlePriceSelect = (fromValue?: number | null, toValue?: number | null): void => {
    setSelectedValues((prev) => ({
      ...prev,
      ...(fromValue !== undefined && { priceFrom: fromValue }),
      ...(toValue !== undefined && { priceTo: toValue }),
    }));
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams();

    const addParamIfValid = (key: string, value: number | string | null | undefined) => {
      if (value !== null && value !== undefined && value !== "") {
        queryParams.set(key, value.toString());
      }
    };

    addParamIfValid("emiMin", selectedValues.emiFrom);
    addParamIfValid("emiMax", selectedValues.emiTo);
    addParamIfValid("priceMin", selectedValues.priceFrom);
    addParamIfValid("priceMax", selectedValues.priceTo);

    const queryString = queryParams.toString();
    const url = queryString
      ? `/search?${queryString}&source=${NavigationKeys.HERO}`
      : `/search?source=${NavigationKeys.HERO}`;

    router.push(url);
  };

  return (
    <FilterProvider searchParams={params}>
      <section className="absolute z-10 w-full transform px-5 pt-10 lg:left-1/2 lg:top-1/4 lg:w-fit lg:-translate-x-1/2 lg:-translate-y-1/2 lg:px-0 lg:pt-0 lg:pt-8">
        <div className="mb-3 flex items-center justify-center lg:justify-between">
          <h1 className="mb-3 font-urbanist text-3xl font-bold text-white lg:mb-0 lg:max-w-md lg:text-4xl xxl:max-w-fit">
            Find Your Perfect Used Car in Dubai
          </h1>
          <Link href={"/sell-used-car-dubai"} className="flex">
            <div className="relative hidden w-72 items-center rounded-2xl bg-white/10 text-white backdrop-blur-sm hover:bg-white/5 lg:flex">
              <h3 className="inter py-3 pl-4 text-sm">
                Sell your used car <br /> hassle free
              </h3>
              <img
                className="absolute right-0 z-20 rounded-t-[2rem]"
                src="/assets/images/hero-car.svg"
                alt="Car illustration"
              />
              <img
                className="self-rotate-animation absolute -top-6 right-12 z-10 rounded-t-[2rem]"
                src="/assets/images/dollar-sign.svg"
                alt="Car illustration"
              />
            </div>
          </Link>
        </div>

        <div className="mb-4 hidden items-center justify-between rounded-3xl border-4 border-[#7d90aa90] bg-white p-2 lg:flex lg:w-[54rem] lg:rounded-4xl lg:border-8 xl:w-[60rem] 2xl:w-[75rem]">
          <div className="flex flex-grow items-center gap-4 px-1 lg:px-3">
            <div className="hidden flex-1 lg:block">
              <Dropdown
                label="Monthly Installments"
                onSelect={handleEmiSelect}
                minValue={500}
                maxValue={25000}
                customSteps={[
                  "any",
                  500,
                  750,
                  1000,
                  1250,
                  1500,
                  1750,
                  2000,
                  2250,
                  2500,
                  2750,
                  3000,
                  3250,
                  3500,
                  3750,
                  4000,
                  4500,
                  5000,
                  6000,
                  7000,
                  8000,
                  9000,
                  10000,
                  12000,
                  14000,
                  16000,
                  18000,
                  20000,
                  25000,
                ]}
              />
            </div>

            <div className="hidden h-6 w-px bg-[#ECF0F5] lg:block"></div>

            <div className="hidden flex-1 lg:block">
              <Dropdown
                label="Full Price"
                onSelect={handlePriceSelect}
                minValue={25000}
                maxValue={2500000}
                customSteps={[25000, 50000, 75000, 100000, 125000, 150000, 175000, 200000, 225000, 2500000]}
              />
            </div>
          </div>

          <button
            onClick={handleSearch}
            className="inter hidden cursor-pointer items-center rounded-2xl bg-primary p-4 text-white transition-all duration-300 ease-in-out hover:bg-primary-dark lg:flex"
          >
            <IconSearch className="transform transition-transform" />
            <span className="ml-3">
              Show All Cars
              {/* Show All <span className="font-bold">{carTotals?.count ?? 0}</span> Cars */}
            </span>
          </button>
        </div>

        <button
          onClick={handleSearch}
          className="inter flex w-full cursor-pointer items-center justify-center rounded-2xl bg-primary py-4 text-center text-white transition-all duration-300 ease-in-out hover:bg-primary-dark lg:hidden"
        >
          <IconSearch className="transform transition-transform" />
          {/* <span className="ml-3">Show All {isLoading ? "..." : (carTotals?.count ?? 0)} Cars</span> */}
          <span className="ml-3">Show All Cars</span>
        </button>
      </section>
    </FilterProvider>
  );
};

export default HeroSectionSearch;
