import Link from "next/link";
import React from "react";

import { cn } from "@/lib/utils";
import { HeadingTag } from "@/utils/constants";
import IconArrowRight from "../Icons/IconArrowRight";
import IconFire from "../Icons/IconFire";

interface SectionTitleProps {
  title: string;
  showButton?: boolean;
  showIcon?: boolean;
  icon?: React.ReactNode;
  buttonText?: string;
  buttonIcon?: React.ReactNode;
  buttonNavigation?: string;
  className?: string;
  headingClassName?: string;
  as?: HeadingTag;
}

const SectionTitle: React.FC<SectionTitleProps> = ({
  title,
  showButton = true,
  showIcon = true,
  icon = <IconFire />,
  buttonText = "See All",
  buttonIcon = <IconArrowRight />,
  buttonNavigation = "/",
  className,
  headingClassName,
  as: HeadingTag = "h2",
}) => {
  return (
    <div className={cn(className, "mb-8 flex items-center justify-between")}>
      <div className="flex items-center">
        {showIcon && <div className="transform transition-transform duration-300 hover:scale-110">{icon}</div>}
        <HeadingTag className={cn(headingClassName, "ml-2 font-urbanist text-2xl font-semibold lg:text-3xl")}>
          {title}
        </HeadingTag>
      </div>

      {showButton && (
        <div>
          <Link href={buttonNavigation}>
            <button className="inter group flex items-center rounded-2xl bg-white px-4 py-3 transition-all duration-300 ease-in-out active:scale-95 active:transform active:shadow-sm">
              <span className="mr-2 text-sm font-semibold text-black transition-colors duration-300 group-hover:text-primary">
                {buttonText}
              </span>
              <div className="transform transition-transform duration-300 group-hover:translate-x-1">{buttonIcon}</div>
            </button>
          </Link>
        </div>
      )}
    </div>
  );
};

export default SectionTitle;
