"use client";
import Link from "next/link";
import React from "react";

import { formatImageUrl } from "@/lib/utils";
import OptimizedImage from "../common/images/OptimizedImage";

interface TeamCardProps {
  agent: any;
}

const TeamCard: React.FC<TeamCardProps> = ({ agent }) => {
  const maxCharLength = 100;

  const truncateText = (text: string) => {
    if (text.length <= maxCharLength) return text;
    return text.substring(0, maxCharLength) + "...";
  };

  let agentPhoto = agent.user?.photo;
  if (typeof agentPhoto !== "string") {
    agentPhoto = agentPhoto.src;
  } else {
    agentPhoto = formatImageUrl(agentPhoto);
  }
  function formatLanguages(languages: string[]) {
    if (!languages || !Array.isArray(languages)) {
      return "";
    }

    const validLanguages = languages.filter((lang) => typeof lang === "string" && lang.trim() !== "");

    return validLanguages.join(", ");
  }

  return (
    <Link
      key={agent.id}
      href={`/our-team/${agent.id}`}
      className="block cursor-pointer rounded-[2rem] bg-white transition-transform"
      tabIndex={0}
      aria-label={`View ${agent.user?.name || "Agent"}'s profile`}
    >
      <div className="rounded-[2rem] bg-white p-1">
        <div className="relative h-80">
          <OptimizedImage
            width={375}
            maxWidth={500}
            sizes="(max-width: 768px) 100vw, 375px"
            imageLayout="custom"
            src={agentPhoto ?? (agent.image || "/assets/images/default-agent.svg")}
            alt={`${agent.user?.name ?? agent.name}-photo`}
            className="rounded-[2rem] object-cover object-top"
          />
        </div>
        <div className="flex flex-col items-center px-10 py-5">
          <span className="urbanist text-xl font-semibold text-primary-foreground">
            {agent.user?.name ?? agent.name}
          </span>
          <span className="inter text-sm font-normal text-[#292D3480]">
            {agent?.designation
              ?.replace(/-/g, " ") // Replace hyphens with spaces
              .replace(/(?:^|\s)\S/g, (match: string) => match.toUpperCase())}{" "}
          </span>
          {agent.languages && agent?.teamCategory?.toLowerCase() !== "management" && (
            <span className="inter mt-2 text-sm font-normal text-[#292D3480]">{formatLanguages(agent?.languages)}</span>
          )}
          {agent.agentShortIntro && (
            <div className="inter px-2 pt-3 text-center">
              <p className="text-base font-normal text-[#4D4D4D]">{truncateText(agent.agentShortIntro)}</p>
              {agent.agentShortIntro.length > maxCharLength && (
                <span className="mt-2 inline-block text-sm font-medium text-primary hover:text-primary/80">
                  View Profile
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    </Link>
  );
};

export default TeamCard;
