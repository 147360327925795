"use client";

import Link from "next/link";
import { useState } from "react";

import Icon from "@/components/Icons/Icon";
import IconWhatsapp from "@/components/Icons/IconWhatsapp";
import IconWhatsappHover from "@/components/Icons/IconWhatsappHover";
import { socialMediaIcons } from "@/utils/constants";

const HeroSectionSocialIcons = () => {
  const [hoveredIconKey, setHoveredIconKey] = useState<string | null>(null);
  return (
    <section className="absolute bottom-2 flex w-full flex-row justify-between px-5 lg:bottom-20 lg:right-5 lg:w-fit lg:flex-col lg:px-0 megawide:right-10">
      <div className="flex flex-row rounded-full border border-[#ffffff30] bg-grey p-1 backdrop-blur-2xl lg:flex-col">
        {socialMediaIcons.map(({ default: DefaultIcon, hover: HoverIcon, key, url }) => (
          <div
            className="cursor-pointer rounded-full hover:bg-white"
            key={key}
            onMouseEnter={() => setHoveredIconKey(key)}
            onMouseLeave={() => setHoveredIconKey(null)}
          >
            <Link href={url} target="_blank">
              <Icon icon={hoveredIconKey === key ? <HoverIcon /> : <DefaultIcon />} />
            </Link>
          </div>
        ))}
      </div>
      {/* <div className={`relative transition-all duration-500 ease-in-out`}>
        <div
          className="flex h-14 w-14 cursor-pointer items-center justify-center rounded-full bg-grey backdrop-blur-2xl hover:bg-white lg:mt-5"
          onMouseEnter={() => setHoveredIconKey("whatsapp")}
          onMouseLeave={() => setHoveredIconKey(null)}
        >
          <Icon icon={hoveredIconKey === "whatsapp" ? <IconWhatsappHover /> : <IconWhatsapp />} />
        </div>
      </div> */}
    </section>
  );
};

export default HeroSectionSocialIcons;
