import { FC } from "react";
interface IconStarsProps {
  fillColor?: string;
  className?: string;
  emptyColor?: string;
  rating: number;
}

const IconStars: FC<IconStarsProps> = ({ fillColor = "#FFD700", emptyColor = "#E0E0E0", rating , className }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);

  return (
    <svg
      width="80"
      height="21"
      viewBox="0 0 113 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={` ${className}`}
    >
      {Array.from({ length: fullStars }).map((_, i) => (
        <path
          key={`full-${i}`}
          d="M19.6283 9.02342L15.5405 13.0082L16.5058 18.6362C16.5478 18.8823 16.4468 19.131 16.2447 19.278C16.1305 19.3613 15.9946 19.4033 15.8588 19.4033C15.7544 19.4033 15.6494 19.3784 15.5536 19.3279L10.4992 16.6707L5.44541 19.3272C5.22491 19.444 4.9565 19.425 4.75437 19.2773C4.55225 19.1303 4.45119 18.8816 4.49319 18.6355L5.45853 13.0075L1.37009 9.02342C1.19159 8.84886 1.12662 8.58767 1.20406 8.35077C1.2815 8.11386 1.48691 7.93995 1.73431 7.90386L7.38397 7.08355L9.91053 1.96348C10.1317 1.51526 10.8667 1.51526 11.0878 1.96348L13.6144 7.08355L19.2641 7.90386C19.5115 7.93995 19.7169 8.1132 19.7943 8.35077C19.8717 8.58833 19.8068 8.8482 19.6283 9.02342Z"
          fill={fillColor}
          transform={`translate(${i * 23}, 0)`}
        />
      ))}

      {hasHalfStar && (
        <g transform={`translate(${fullStars * 23}, 0)`}>
          <path
            d="M10.4992 16.6707L5.44541 19.3272C5.22491 19.444 4.9565 19.425 4.75437 19.2773C4.55225 19.1303 4.45119 18.8816 4.49319 18.6355L5.45853 13.0075L1.37009 9.02342C1.19159 8.84886 1.12662 8.58767 1.20406 8.35077C1.2815 8.11386 1.48691 7.93995 1.73431 7.90386L7.38397 7.08355L9.91053 1.96348C10.0211 1.73958 10.2601 1.62744 10.4992 1.62744V16.6707Z"
            fill={fillColor}
          />
          <path
            d="M19.6283 9.02342L15.5405 13.0082L16.5058 18.6362C16.5478 18.8823 16.4468 19.131 16.2447 19.278C16.1305 19.3613 15.9946 19.4033 15.8588 19.4033C15.7544 19.4033 15.6494 19.3784 15.5536 19.3279L10.4992 16.6707V1.62744C10.7383 1.62744 10.9773 1.73958 11.0878 1.96348L13.6144 7.08355L19.2641 7.90386C19.5115 7.93995 19.7169 8.1132 19.7943 8.35077C19.8717 8.58833 19.8068 8.8482 19.6283 9.02342Z"
            fill={emptyColor}
          />
        </g>
      )}

      {Array.from({ length: emptyStars }).map((_, i) => (
        <path
          key={`empty-${i}`}
          d="M19.6283 9.02342L15.5405 13.0082L16.5058 18.6362C16.5478 18.8823 16.4468 19.131 16.2447 19.278C16.1305 19.3613 15.9946 19.4033 15.8588 19.4033C15.7544 19.4033 15.6494 19.3784 15.5536 19.3279L10.4992 16.6707L5.44541 19.3272C5.22491 19.444 4.9565 19.425 4.75437 19.2773C4.55225 19.1303 4.45119 18.8816 4.49319 18.6355L5.45853 13.0075L1.37009 9.02342C1.19159 8.84886 1.12662 8.58767 1.20406 8.35077C1.2815 8.11386 1.48691 7.93995 1.73431 7.90386L7.38397 7.08355L9.91053 1.96348C10.1317 1.51526 10.8667 1.51526 11.0878 1.96348L13.6144 7.08355L19.2641 7.90386C19.5115 7.93995 19.7169 8.1132 19.7943 8.35077C19.8717 8.58833 19.8068 8.8482 19.6283 9.02342Z"
          fill={emptyColor}
          transform={`translate(${(fullStars + (hasHalfStar ? 1 : 0) + i) * 23}, 0)`}
        />
      ))}
    </svg>
  );
};

export default IconStars;
