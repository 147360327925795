"use client";

import { Vehicle } from "@alba-cars/common-modules";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, Virtual } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import IconArrowRight from "@/components/Icons/IconArrowRight";
import CarsCard from "@/components/ui/CarsCard";

const CarsSlider = ({ Cars, id }: { Cars: Vehicle[]; id: string }) => {
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: false, amount: 0.1 });

  const containerVariants = {
    hidden: {
      opacity: 0,
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.1,
        staggerChildren: 0.1,
      },
    },
  };

  const slideVariants = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
    exit: {
      opacity: 0,
      x: -100,
      transition: {
        duration: 0.3,
        ease: "easeIn",
      },
    },
  };

  return (
    <motion.section
      ref={containerRef}
      initial="hidden"
      animate={isInView ? "visible" : "hidden"}
      viewport={{ once: false, amount: 0.1 }}
      variants={containerVariants}
      className="safari-fix relative ml-auto w-[94vw] xxxl:w-[90vw] megawide:w-[75vw]"
    >
      <div className="relative">
        <Swiper
          modules={[Navigation, Virtual]}
          navigation={{
            nextEl: `.swiper-next-${id}`,
            prevEl: `.swiper-prev-${id}`,
          }}
          resistance={false}
          resistanceRatio={0}
          watchSlidesProgress={true}
          virtual={{
            addSlidesAfter: 1,
            addSlidesBefore: 1,
            enabled: true,
          }}
          preventInteractionOnTransition={true}
          slidesPerView={1}
          spaceBetween={16}
          updateOnWindowResize={true}
          breakpoints={{
            375: { slidesPerView: 1.2 },
            640: { slidesPerView: 2.5 },
            1024: { slidesPerView: 3 },
            1280: { slidesPerView: 4 },
            1700: { slidesPerView: 5 },
            2500: { slidesPerView: 6 },
            3000: { slidesPerView: 7 },
          }}
        >
          {Cars.map((vehicle: Vehicle, index: number) => (
            <SwiperSlide key={vehicle.id} virtualIndex={index}>
              <motion.div variants={slideVariants}>
                <CarsCard car={vehicle} useSlider={false} />
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div
          className={`swiper-next-${id} absolute right-4 top-1/2 z-20 hidden h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white text-black shadow-soft-grey lg:flex`}
        >
          <IconArrowRight height="18" width="18" />
        </div>
        <div className="pointer-events-none absolute -right-10 top-0 z-10 hidden h-full w-40 bg-slider-gradient lg:block"></div>
        <div
          className={`swiper-prev-${id} absolute -left-4 top-1/2 z-20 hidden h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-white text-black shadow-soft-grey lg:flex`}
        >
          <IconArrowRight height="18" width="18" className="rotate-180" />
        </div>
      </div>
    </motion.section>
  );
};

export default CarsSlider;
