"use client";

import { motion } from "framer-motion";
import Image from "next/image";
import { useEffect, useState } from "react";

import IconPlayVideo from "@/components/Icons/IconPlayVideo";
import Modal from "@/components/ui/Modal";

const ShowroomVideo = () => {
  const [isVideoOpen, setIsVideoOpen] = useState(false);

  useEffect(() => {
    if (isVideoOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }

    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isVideoOpen]);

  return (
    <>
      <div className="relative flex h-64 items-center justify-center lg:h-[40rem] lg:w-3/4">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="absolute h-full w-full"
        >
          <Image
            src="/assets/images/showroom.jpg"
            layout="fill"
            alt="Showroom Image"
            className="rounded-3xl object-cover object-center"
          />
        </motion.div>
        <button
          onClick={() => setIsVideoOpen(true)}
          className="absolute z-30 cursor-pointer transition-transform hover:scale-110"
        >
          <IconPlayVideo className="w-2"  />
        </button>
      </div>

      <Modal
        isOpen={isVideoOpen}
        onClose={() => setIsVideoOpen(false)}
        size="large"
        className="!bg-transparent !p-10"
        closeButtonClassName="text-transparent"
      >
        <div className="aspect-video w-full">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/xf0Vd0ySy0g?si=Yl6A8wFJbmN4aVco&autoplay=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
            className="h-full w-full rounded-lg"
          />
        </div>
      </Modal>
    </>
  );
};

export default ShowroomVideo;
