import { FC } from "react";

interface IconThirdStarProps {
  className?: string;
}

const IconThirdStar: FC<IconThirdStarProps> = () => {
  return (
    <svg width="44" height="43" viewBox="0 0 44 43" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_338_2384)">
        <path
          d="M18.2487 39.4168C18.2487 34.665 18.3447 28.3162 21.7047 24.9562C25.0647 21.5961 31.4136 21.5002 36.1654 21.5002C31.4136 21.5002 25.0647 21.4042 21.7047 18.0442C18.3447 14.6841 18.2487 8.33529 18.2487 3.5835C18.2487 8.33529 18.1527 14.6841 14.7927 18.0442C11.4327 21.4042 5.08383 21.5002 0.332031 21.5002C5.08383 21.5002 11.4327 21.5961 14.7927 24.9562C18.1527 28.3162 18.2487 34.665 18.2487 39.4168Z"
          fill="white"
        />
        <path
          d="M37.957 16.125C37.957 14.6995 37.9858 12.7948 38.9938 11.7868C40.0018 10.7788 41.9065 10.75 43.332 10.75C41.9065 10.75 40.0018 10.7212 38.9938 9.7132C37.9858 8.70519 37.957 6.80054 37.957 5.375C37.957 6.80054 37.9282 8.70519 36.9202 9.7132C35.9122 10.7212 34.0076 10.75 32.582 10.75C34.0076 10.75 35.9122 10.7788 36.9202 11.7868C37.9282 12.7948 37.957 14.6995 37.957 16.125Z"
          fill="white"
        />
        <path
          d="M36.1654 35.8332C36.1654 34.8828 36.1846 33.613 36.8566 32.941C37.5286 32.269 38.7983 32.2498 39.7487 32.2498C38.7983 32.2498 37.5286 32.2306 36.8566 31.5586C36.1846 30.8866 36.1654 29.6169 36.1654 28.6665C36.1654 29.6169 36.1462 30.8866 35.4742 31.5586C34.8022 32.2306 33.5324 32.2498 32.582 32.2498C33.5324 32.2498 34.8022 32.269 35.4742 32.941C36.1462 33.613 36.1654 34.8828 36.1654 35.8332Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_338_2384">
          <rect width="43" height="43" fill="white" transform="translate(0.332031)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconThirdStar;
