"use client";
import { motion, useScroll, useTransform } from "framer-motion";
import Image from "next/image";
import Link from "next/link";
import React from "react";

import IconCheck from "@/components/Icons/IconCheck";
import Button from "@/components/ui/buttons/Button";
import TradeinImage from "@/public/assets/images/trade-in.jpg";
import { DEFAULT_PLACEHOLDER } from "@/utils/constants";
import AnimatedWarrantyCar from "./AnimatedWarrantyCar";

const TradeIn: React.FC = () => {
  const ref = React.useRef(null);
  const { scrollYProgress } = useScroll({ target: ref, offset: ["start end", "end start"] });

  const leftColumnTranslateX = useTransform(scrollYProgress, [0, 0.35, 0.5, 1], ["-40%", "0%", "0%", "0"]);

  return (
    <section ref={ref} className="my-2 xl:my-28">
      <div className="grid grid-cols-1 items-stretch gap-4 xl:h-full xl:grid-cols-6 xxl:grid-cols-4">
        <motion.div
          style={{ translateX: leftColumnTranslateX }}
          className="relative hidden w-full items-center overflow-hidden rounded-3xl lg:block xl:col-span-2 xxl:col-span-1"
        >
          <Image
            src={TradeinImage}
            className="absolute z-10 h-full w-full rounded-3xl"
            priority
            placeholder="blur"
            blurDataURL={DEFAULT_PLACEHOLDER}
            width={600}
            height={100}
            alt="Trade In"
            sizes="(max-width: 1280px) 100vw, 1280px"
          />
          <div className="absolute bottom-5 z-20 flex w-full items-center justify-center">
            <Link
              href={"/sell-used-car-dubai"}
              className="!rounded-2xl border border-white bg-white text-center text-base font-semibold hover:bg-[#8f0d0d] hover:text-white xl:w-11/12"
            >
              <Button buttonText="Start Now" className="px-4 py-3 font-urbanist text-xl font-semibold"></Button>
            </Link>
          </div>

          {/* <div className="z-10 flex justify-between xl:flex-col xl:justify-center">
            <div className="flex flex-col items-start xl:my-6 xl:items-center xl:text-center">
              <img src="/assets/images/trade-in.svg" className="scale-110" alt="Trade In" />

              <p className="urbanist pt-4 text-4xl font-medium text-white">We cash out your old car</p>
            </div>
            <div className="relative mb-4 flex h-28 max-h-[19rem] items-center justify-center md:h-20 lg:h-[10rem] xl:h-[19rem] xl:w-full">
              <img
                src="/assets/images/radial-green-circle.svg"
                className="xl:-rotate-8 absolute inset-0 -left-8 -translate-x-60 translate-y-80 scale-[2.5] blur-[2.5px] xl:translate-x-0 xl:translate-y-1/2"
                alt="Background image"
              />
              <div className="z-20">
                <img
                  src="/assets/images/trade-cars.svg"
                  className="inset-0 z-20 mt-5 -translate-y-5 object-contain xl:mt-0 xl:scale-125"
                  alt="Trade Cars"
                />
              </div>
              <div className="absolute z-20 -translate-x-4">
                <RotatingExchangeIcon />
              </div>
            </div>
          </div>
          <div className="relative z-20 flex items-center justify-center">
            <Link
              href={"/"}
              className="w-8/12 !rounded-2xl border border-white bg-white text-center text-base font-semibold text-black hover:bg-[#001A03] hover:text-white xl:w-11/12"
            >
              <Button buttonText="Begin" className="p-4"></Button>
            </Link>
          </div> */}
        </motion.div>

        <div className="flex flex-col gap-4 xl:col-span-4 xl:h-full xxl:col-span-3">
          {/* Rest of the component remains the same */}
          <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
            <div className="relative flex max-w-full flex-col items-center justify-between rounded-4xl bg-[#fff] pb-9">
              <div className="absolute top-6 mx-auto flex flex-wrap justify-center space-x-2 xxxl:max-w-xl xxxl:px-6">
                <div className="inter flex items-center space-x-2 rounded-full bg-[#EDF1F5] px-4 py-2 font-medium text-black xl:mb-2 2xl:mb-0">
                  <IconCheck />
                  <span className="text-xs md:text-sm">150+ Point Inspection</span>
                </div>
                <div className="inter sxs:mt-0 mt-2 flex items-center space-x-2 rounded-full bg-[#EDF1F5] px-4 py-2 font-medium text-black">
                  <IconCheck />
                  <span className="text-xs md:text-sm">Mileage Verified</span>
                </div>
                <div className="inter mt-2 flex items-center space-x-2 rounded-full bg-[#EDF1F5] px-4 py-2 font-medium text-black xs:mt-0 xxxl:mt-3">
                  <IconCheck /> <span className="text-xs md:text-sm">RTA Certified </span>
                </div>
              </div>

              <div className="pt-2">
                <div className="relative h-[250px] w-[300px] overflow-hidden ixs:w-[350px] xl:h-[300px] 2xl:w-[450px]">
                  <Image
                    src="/assets/images/car-1.png"
                    alt="Car 1"
                    width={437}
                    height={350}
                    className="absolute top-12 h-full object-contain xl:top-10 2xl:top-12"
                  />
                </div>

                <p className="urbanist mx-auto max-w-xs text-center text-xl font-medium">
                  We handpick only the finest cars to ensure top quality for our customers.
                </p>
              </div>
            </div>
            <div className="relative rounded-4xl bg-[#fff]">
              <AnimatedWarrantyCar
                carBodySrc="/assets/images/car-body.png"
                frontWheelSrc="/assets/images/wheel-front.png"
                rearWheelSrc="/assets/images/wheel-back.png"
                wheelPositions={{
                  desktop: { front: { x: 101, y: 189 }, rear: { x: 302, y: 186 } },
                  mobile: { front: { x: 79, y: 181 }, rear: { x: 235, y: 179 } },
                }}
              />
            </div>
          </div>

          <div className="flex flex-grow flex-col items-center justify-start rounded-4xl bg-[#fff] px-6 lg:flex-row">
            <div className="relative order-6 h-48 w-64 lg:order-first">
              <Image src="/assets/images/approval.svg" alt="finance" width={256} height={192} className="absolute" />
            </div>
            <div className="ml-6 mt-8 flex flex-col justify-center space-y-3 lg:ml-6 lg:mt-0 lg:space-y-3 lg:text-left">
              <p className="flex items-center text-center font-urbanist text-xl font-medium text-primary-foreground lg:text-left">
                Easy finance options for clients with{" "}
                <span className="ml-2 flex items-center justify-center rounded-full bg-black px-4 py-1 text-white lg:ml-2">
                  0%
                </span>
              </p>
              <p className="text-center font-inter text-[#292D34CC] lg:text-left">
                Downpayment with bank finance or lease-to-own options available.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TradeIn;
