import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import Select, { ActionMeta, SingleValue } from "react-select";

interface OptionType {
  label: string;
  value: number | null;
}

interface DropdownProps {
  label: string;
  minValue?: number;
  maxValue?: number;
  step?: number;
  customSteps?: (number | "any")[];
  initialFromValue?: number | null;
  initialToValue?: number | null;
  onSelect?: (fromValue?: number | null, toValue?: number | null) => void;
}

const CustomDropdown: React.FC<DropdownProps> = ({
  label,
  minValue = 0,
  maxValue = 100000,
  step = 10000,
  customSteps,
  initialFromValue = null,
  initialToValue = null,
  onSelect,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [fromValue, setFromValue] = useState<number | null>(initialFromValue);
  const [toValue, setToValue] = useState<number | null>(initialToValue);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const generateValues = (): OptionType[] => {
    const values: OptionType[] = [{ label: "Any", value: null }];

    if (customSteps) {
      const numberSteps = customSteps
        .filter((step): step is number => typeof step === "number")
        .map((value) => ({
          label: value.toLocaleString("en-US"),
          value,
        }));
      return [...values, ...numberSteps];
    }

    for (let i = minValue; i <= maxValue; i += step) {
      values.push({
        label: i.toLocaleString("en-US"),
        value: i,
      });
    }
    return values;
  };

  const values = generateValues();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const formatNumber = (value: number): string => {
    return value.toLocaleString("en-US");
  };

  const handleFromSelect = (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    const value = newValue?.value ?? null;

    if (value === null || toValue === null || value <= toValue) {
      setFromValue(value);
      onSelect?.(value, toValue);
    } else {
      setFromValue(value);
      setToValue(value);
      onSelect?.(value, value);
    }
  };

  const handleToSelect = (newValue: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    const value = newValue?.value ?? null;

    if (value === null || fromValue === null || value >= fromValue) {
      setToValue(value);
      onSelect?.(fromValue, value);
    } else {
      setToValue(value);
      setFromValue(value);
      onSelect?.(value, value);
    }
  };

  const getAvailableToValues = (): OptionType[] => {
    if (fromValue === null) return values;
    return values.filter((option) => option.value === null || option.value >= fromValue);
  };

  const getAvailableFromValues = (): OptionType[] => {
    if (toValue === null) return values;
    return values.filter((option) => option.value === null || option.value <= toValue);
  };

  const selectClassNames = {
    control: (state: { isFocused: boolean }) =>
      clsx(
        "group flex items-center bg-secondary-light !border border-secondary-light !rounded-2xl p-1.5",
        "min-h-[42px] hover:cursor-pointer hover:!border-grey-border",
        "transition-all duration-300",
        state.isFocused ? "!border-grey-border !ring-1 !ring-grey-border" : "!outline-gray-300",
      ),
    valueContainer: () => "flex flex-wrap flex-1 px-3 py-1 gap-1",
    placeholder: () => "inter text-secondary-foreground text-sm",
    input: () => "text-secondary-foreground font-medium text-sm inter focus:outline-none p-0 m-0",
    menu: () => "!mb-2 !rounded-2xl bg-white absolute w-full !z-50 !overflow-hidden",
    menuList: () => "!max-h-56 inter font-medium !z-50",
    option: ({ isSelected, isFocused }: { isSelected: boolean; isFocused: boolean }) =>
      clsx(
        "px-4 py-2 !cursor-pointer inter font-medium !my-1",
        isSelected && "!bg-grey-border text-white",
        !isSelected && isFocused && "!bg-secondary-light text-black",
        !isSelected && !isFocused && "text-gray-800 hover:bg-gray-50",
      ),
    singleValue: () => "text-[#000000] inter font-medium text-sm !overflow-visible",
    indicatorsContainer: () => "flex items-center ",
    dropdownIndicator: (state: { selectProps: { menuIsOpen: boolean } }) =>
      clsx(
        "text-gray-400 hover:text-gray-600 transition-transform duration-300",
        state.selectProps.menuIsOpen && "rotate-180",
      ),
    clearIndicator: () => clsx("!text-primary ", "lg:opacity-0 lg:group-hover:opacity-100 !p-0"),
    indicatorSeparator: () => "hidden",
    noOptionsMessage: () => "text-secondary-foreground p-4",
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex w-full cursor-pointer items-center justify-between py-1 font-inter font-medium text-secondary lg:py-2"
      >
        <div className="text-sm text-primary-foreground">
          <div className="text-left text-gray-500">{label}</div>
          <div className="!items-left flex">
            {fromValue !== null ? `${formatNumber(fromValue)} - ` : ""}
            {toValue !== null
              ? `${fromValue === null ? "Any - " : ""}${formatNumber(toValue)}`
              : fromValue === null
                ? "Any Amount"
                : "Any"}
          </div>
        </div>
        <svg
          className={`h-4 w-4 transition-transform ${isOpen ? "rotate-180" : ""}`}
          fill="none"
          stroke="#65717F"
          viewBox="0 0 24 24"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute z-50 mt-6 w-full rounded-xl bg-white p-4 shadow-soft-grey transition-all duration-1000 ease-in-out">
          <div className="flex space-x-2 lg:space-x-4">
            <div className="flex-1">
              <div className="mb-2 font-inter text-sm font-medium text-secondary-foreground">From</div>
              <Select<OptionType>
                value={
                  fromValue === null
                    ? { label: "Any", value: null }
                    : { label: formatNumber(fromValue), value: fromValue }
                }
                onChange={handleFromSelect}
                options={getAvailableFromValues()}
                placeholder="Select..."
                isClearable
                classNames={selectClassNames}
                isSearchable={false}
              />
            </div>

            <div className="flex-1">
              <div className="mb-2 font-inter text-sm font-medium text-secondary-foreground">To</div>
              <Select<OptionType>
                value={
                  toValue === null ? { label: "Any", value: null } : { label: formatNumber(toValue), value: toValue }
                }
                onChange={handleToSelect}
                options={getAvailableToValues()}
                placeholder="Select..."
                isClearable
                classNames={selectClassNames}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomDropdown;
