import { FC } from "react";

interface IconFireProps {
  className?: string;
}

const IconFire: FC<IconFireProps> = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.9217 30.8415C1.6247 28.2625 5.44955 22.7221 3.1875 16.6314C5.19345 16.4443 7.65774 21.9823 7.23073 19.4199C4.63591 9.1942 14.8327 9.72574 12.2918 0.800049C22.2952 2.16938 18.5971 11.2595 23.1553 13.9312C24.4252 14.2541 26.0468 13.341 23.5637 8.43343C34.3378 21.1068 26.6971 31.2802 17.0015 31.1996C27.2299 28.1195 18.6935 19.2133 14.1737 16.1247C13.9682 21.1856 19.4332 24.8426 15.694 26.5889C15.7832 25.0791 15.7969 23.5515 12.1192 21.3294C13.4518 27.1877 8.83202 26.3834 12.9217 30.8415Z"
        fill="#FF3448"
      />
    </svg>
  );
};

export default IconFire;
