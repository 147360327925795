"use client";

import classNames from "classnames";
import React, { ButtonHTMLAttributes, HTMLAttributes, ReactNode, useEffect, useRef, useState } from "react";

import Icon from "@/components/Icons/Icon";
import IconPlus from "@/components/Icons/IconPlus";
import { HeadingTag } from "@/utils/constants";

interface AccordionTriggerProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  expanded: boolean;
  id: string;
  as?: React.ElementType;
  headingTag?: HeadingTag;
}

const AccordionTrigger: React.FC<AccordionTriggerProps> = ({
  children,
  className,
  expanded,
  onClick,
  id,
  as: Component = "button",
  headingTag: HeadingTag = "h3",
  ...props
}) => (
  <div className="flex">
    <Component
      className={classNames(
        "flex h-14 w-full flex-1 items-center justify-between border-none bg-white p-6 text-left",
        className,
      )}
      onClick={onClick}
      aria-expanded={expanded}
      aria-controls={`accordion-content-${id}`}
      id={`accordion-header-${id}`}
      type={Component === "button" ? "button" : undefined}
      {...props}
    >
      <HeadingTag className="accordion-heading">{children}</HeadingTag>
      <Icon
        backgroundColor="secondary-light"
        width={35}
        height={35}
        className="p-2"
        icon={
          <IconPlus
            className={classNames("ease-[cubic-bezier(0.22, 1, 0.36, 1)] transition-transform duration-500", {
              "rotate-45": expanded,
            })}
          />
        }
      />
    </Component>
  </div>
);

interface AccordionContentProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  className?: string;
  expanded: boolean;
  id: string;
  as?: React.ElementType;
}

const AccordionContent: React.FC<AccordionContentProps> = ({
  children,
  className,
  expanded,
  id,
  as: Component = "div",
  ...props
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (contentRef.current) {
      setHeight(contentRef.current.scrollHeight);
    }
  }, [expanded]);

  return (
    <div
      className={classNames(
        "overflow-hidden bg-white text-sm text-gray-600 will-change-[max-height]",
        // expanded ? "max-h-[var(--current-height, 1000px)]" : "max-h-0",
        {
          "animate-slideDown": expanded,
          "animate-slideUp": !expanded,
        },
        className,
      )}
      id={`accordion-content-${id}`}
      aria-labelledby={`accordion-header-${id}`}
      role="region"
      data-state={expanded ? "open" : "closed"}
      style={{ "--current-height": `${height}px` } as React.CSSProperties}
      {...props}
    >
      <Component className="p-6 pt-4 !opacity-100" ref={contentRef}>
        {children}
      </Component>
    </div>
  );
};

interface AccordionItemProps {
  value: string;
  trigger: ReactNode;
  content: ReactNode;
  className?: string;
}

const AccordionItem: React.FC<AccordionItemProps> = ({ value, trigger, content, className }) => {
  return (
    <div
      className={classNames(
        "mt-0 overflow-hidden border-b border-[#f6f6f9] pb-1 ps-1 pt-1 first:rounded-t-4xl last:rounded-b-4xl last:border-b-0",
        className,
      )}
      data-value={value}
    >
      {trigger}
      {content}
    </div>
  );
};

interface AccordionItem {
  value: string;
  header: ReactNode;
  content: ReactNode;
}

interface AccordionDemoProps {
  items: ReadonlyArray<AccordionItem>;
  title?: string;
  triggerElement?: React.ElementType;
  contentElement?: React.ElementType;
  headingTag?: HeadingTag;
}

const AccordionDemo: React.FC<AccordionDemoProps> = ({
  items,
  title,
  triggerElement = "button",
  contentElement = "div",
  headingTag = "h3",
}) => {
  // const [expandedItem, setExpandedItem] = useState<string | null>(items[0]?.value || null);
  const [expandedItem, setExpandedItem] = useState<string | null>(null);

  const handleToggle = (value: string) => {
    setExpandedItem((prevExpanded) => (prevExpanded === value ? null : value));
  };

  return (
    <div className="w-full rounded-4xl bg-white" aria-label={title || "Accordion Section"}>
      {title && <h4 className="urbanist rounded-t-2xl bg-white p-6 text-lg font-semibold lg:text-3xl">{title}</h4>}
      {items.map((item, idx) => {
        const isExpanded = expandedItem === item.value;

        return (
          <AccordionItem
            className={title && idx === 0 ? "inter !mt-0" : "inter"}
            key={item.value}
            value={item.value}
            trigger={
              <AccordionTrigger
                expanded={isExpanded}
                onClick={() => handleToggle(item.value)}
                id={item.value}
                as={triggerElement}
                headingTag={headingTag}
                className="!font-inter text-sm !font-medium !text-primary-foreground lg:text-xl"
              >
                {item.header}
              </AccordionTrigger>
            }
            content={
              <AccordionContent expanded={isExpanded} id={item.value} as={contentElement}>
                {item.content}
              </AccordionContent>
            }
          />
        );
      })}
    </div>
  );
};

export default AccordionDemo;
